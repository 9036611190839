import {
  Link,
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { useState } from 'react'
import { ethers } from "ethers"
import MusicNFTMarketplaceAbi from '../contractsData/MusicNFTMarketplace.json'
import MusicNFTMarketplaceAddress from '../contractsData/MusicNFTMarketplace-address.json'
import USDTabi from '../contractsData/USDTabi.json'
import USDTaddress from '../contractsData/USDT-address.json'
import { Spinner, Navbar, Nav, Button, Container } from 'react-bootstrap'
import logo from './logo.svg'

import MyTokens from './MyTokens.js'
import MyMarketPlace from './MyMarketPlace.js'


import './App.css';
import backg1 from "./Background.jpg";

function App() {
  const [loading, setLoading] = useState(true)
  const [account, setAccount] = useState(null)
  const [contract, setContract] = useState({})
  const [ucontract, setUContract] = useState({})


  const web3Handler = async () => {
    const network = await window.ethereum.request({ method: 'net_version' });
    if (network !== '137') { //8001
      try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x89' }], //0x89 0x13881
      });
    } catch (error) {
        console.error(error);
    }

    }

    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    setAccount(accounts[0])
    // Get provider from Metamask
    console.log("accounts[0]: ",accounts[0]);
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    console.log("provider: ",provider);
    const signer = provider.getSigner()
    console.log("signer: ",signer);
    loadContract(signer)
  }

  const loadContract = async (signer) => {
    // Get deployed copy of music nft marketplace contract
    const contract = new ethers.Contract(MusicNFTMarketplaceAddress.address, MusicNFTMarketplaceAbi.abi, signer)
//console.log("contract: ",contract);
    setContract(contract)
    const usdtcontract = new ethers.Contract(USDTaddress.address, USDTabi.abi, signer)
    console.log("contract: ",usdtcontract);
    setUContract(usdtcontract)

    setLoading(false)
  }
  return (
    <BrowserRouter >
      <div style={{ backgroundImage:`url(${backg1})`}} className="App" >

          <Navbar expand="xl"  variant="dark" style={{ backgroundColor : '#212123' }}>
            <Container>
              <Navbar.Brand href="https://terranft.club/fortissimo/" style={{ fontSize: '24px', color:'#a40434'}} >
                <img src={logo} width="82" height="39" className="" alt="" />

              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto my-2 my-lg-0"
            style={{ fontSize: '24  px', fontWeight: "350", minWidth :"400px" }} >

                  <Nav.Link as={Link} style={{ color:'#EA45A7'}} to="/">My MarketPlace</Nav.Link>
                  <Nav.Link as={Link} style={{ color:'#EA45A7'}} to="/my-tokens">My NFTs</Nav.Link>


                </Nav>
                <Nav>
                  {account ? (
                    <Nav.Link

                      target="_blank"
                      rel="noopener noreferrer"
                      className="button nav-button btn-sm mx-4">
                      <Button variant="outline-light"  style={{ color:'#EA45A7'}}>
                        {account.slice(0, 5) + '...' + account.slice(38, 42)}
                      </Button>

                    </Nav.Link>
                  ) : (
                    <Button onClick={web3Handler} variant="outline-light"  style={{ color:'#EA45A7'}}>Connect Wallet</Button>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>




        <div class="container-fluid">
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
              <Spinner animation="border" style={{ display: 'flex' }} />
              <p className='mx-3 my-0' style={{ color:'#EA45A7'}}>Awaiting Metamask Connection...please Connect Wallet</p>
            </div>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
            <Routes>
              <Route path="/" element={
                <MyMarketPlace contract={contract} ucontract={ucontract} account={account}/>
              } />
              <Route path="/my-tokens" element={
                <MyTokens contract={contract} />
              } />


            </Routes>
              </div>
          )}
        </div>

      </div>

    </BrowserRouter>

  );
}

export default App;
